//////////////////////////////////////// IMPORTS ////////////////////////////////////////

import React from 'react';
import { makeStyles, Container, Grid, Typography, Button, Tooltip, IconButton, Icon } from '@material-ui/core';
import { useData } from '../../../DataProvider';
import { Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import sdk from '@hopdrive/sdk';
import fragments from '../../utils/graphQL/fragments';
import Divide from '../../reusable/Divide';
import LaneForm from '../movePlanner/LaneForm';
import ConsumerLocButtonGroup from '../movePlanner/ConsumerLocButtonGroup';
import DatetimeSelect from '../movePlanner/DatetimeSelect';
import ConsumerInfoForm from '../movePlanner/ConsumerInfoForm';
import WorkflowForm from '../movePlanner/WorkflowForm';
import VehicleForm from '../movePlanner/VehicleForm';
import NotesInput from '../movePlanner/NotesInput';
import NotificationManagement from '../../reusable/NotificationManagement';
import { toast } from 'react-toastify';
import moment from 'moment';
import dayjs from 'dayjs';
import { getUserEmail } from '../../utils/authHelper';
import { useTools } from '../../hooks/useTools';
let log = false;
//////////////////////////////////////// FUNCTIONS ////////////////////////////////////////
const queryCustomerConfig = async (customerId, ctx) => {
  const res = await ctx.apolloClient.query({
    query: GET_CUSTOMER_CONFIG,
    variables: { customerId },
  });
  let customer = res.data && res.data.customers && res.data.customers.length ? res.data.customers[0] : null;
  let enrichedConfig = sdk.configs.enrichCustomerConfig(
    sdk.utilities.getPropValue(customer, `config`),
    sdk.utilities.getPropValue(customer, `organization.config`)
  );

  return enrichedConfig;
};

const queryWorkflowSets = async customerId => {
  const res = await sdk.gql.query(GET_WORKFLOW_SETS, { customerId });
  let workflowSets =
    res && res.data && res.data.length ? res.data : [];
  return workflowSets;
};

const getStartTime = () => {
  let start = moment().startOf(`minute`).add(120, `minutes`);
  const addStep = 10 - (start.minutes() % 10);
  start = start.add(addStep, `minutes`);
  return moment.utc(start);
};

const validatePhone = (str = null) => {
  if (str && typeof str === `string`) {
    const cleaned = str.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})?(\d{3})(\d{3})(\d{4})$/);
    if (match) return true;
  }
  return false;
};

const isConsumerFound = appointment => {
  if (!appointment.hasOwnProperty('consumer')) return false;
  if (appointment.consumer == null) return false;
  if (Object.keys(appointment.consumer).length === 0) return false;
  if (!appointment.consumer.name) return false;
  if (!appointment.consumer.phone) return false;
  return true;
};

const isVehicleFound = appointment => {
  if (!appointment.hasOwnProperty('vehicle')) return false;
  if (appointment.vehicle == null) return false;
  if (Object.keys(appointment.vehicle).length === 0) return false;
  if (!appointment.vehicle.vin || !appointment.vehicle.vin.vehicle_vin) return false;
  if (!appointment.vehicle.year) return false;
  if (!appointment.vehicle.make.name) return false;
  if (!appointment.vehicle.model.name) return false;
  if (!appointment.vehicle.refNum) return false;
  return true;
};

const buildInsertableAppointment = (appointment, customerId, emailsToNotify = [], pickupTime) => {
  const userEmail = getUserEmail();
  const config =
    emailsToNotify.length > 0
      ? { emailsToNotify: emailsToNotify, claim_number: appointment.vehicle.refNum }
      : { claim_number: appointment.vehicle.refNum };
  try {
    const formattedPickupTime = dayjs(pickupTime).utc().format();
    return {
      status: 'ready',
      customer_id: customerId,
      pickup_location_id: appointment.pickupLocation.id,
      delivery_location_id: appointment.deliveryLocation.id,
      appointment_time: formattedPickupTime,
      workflow_set_id: appointment.workflowSetId,
      consumer_at_pickup: appointment.consumerPickup,
      consumer_name: appointment.consumer.name,
      consumer_phone: appointment.consumer.phone,
      driver_notes: appointment.notes,
      offered_by: userEmail,
      offered_at: 'now()',
      accepted_terms: false,
      vehicle_year: appointment.vehicle.year,
      vehicle_make: appointment.vehicle.make.name,
      vehicle_model: appointment.vehicle.model.name,
      vehicle_color: appointment.vehicle.color,
      vehicle_vin: appointment.vehicle.vin.vehicle_vin,
      vehicle_manual: appointment.vehicle.manual,
      config: config,
      createdat: 'now()',
      createdby: userEmail,
      updatedat: 'now()',
      updatedby: userEmail,
    };
  } catch (error) {
    console.error('Crashed trying to create insertable appointment', error);
    return null;
  }
};

const insertAppointment = async appointment => {
  log && console.log(`Attempting to insert appointment...`, appointment);
  try {
    const res = await sdk.gql.mutation(INSERT_APPOINTMENT, { appointment });
    if (res && res.data && res.data.id) {
      log && console.log(`>> INSERT appointment success:`, res.data.id);
      toast.success(
        `Your customer's appointment has been created. They will receive a text message with appointment details and payment information.`
      );
      return res.data.id;
    }
  } catch (err) {
    console.error(`Failed to insert appointment:`, err);
    toast.error(
      `Failed to create appointment. Please ensure that you've entered all the correct data and resubmit. If the problem persists, please contact your representative.`
    );
  }
  return false;
};

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function AppointmentAddForm(props) {
  const cls = useStyles();
  const ctx = useData();
  const { goToRoute } = useTools();

  //State variables used by the lane form
  const [subscriptionSkip, setSubscriptionSkip] = React.useState(true);
  const [lane, setLane] = React.useState(null);
  const [lanePair, setLanePair] = React.useState(null);
  const [newLaneId, setNewLaneId] = React.useState(null);
  const [pickupDeliveryChange, setPickupDeliveryChange] = React.useState(false);
  const [doesLaneExist, setDoesLaneExist] = React.useState(false);
  const [isSubscriptionRendering, setIsSubscriptionRendering] = React.useState(false);

  //State variables used by the appointment forms
  const [loading, setLoading] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(parseInt(ctx.customerOverride || ctx.customerId || 0));
  const [customerConfig, setCustomerConfig] = React.useState(null);
  const [refNumLabel, setRefNumLabel] = React.useState(null);
  const [emailsList, setEmailsList] = React.useState([]);
  const [workflowSets, setWorkflowSets] = React.useState([]);
  const [workflow, setWorkflow] = React.useState(null);
  const [conciergeSla, setConciergeSla] = React.useState(null);
  const [consumerLocation, setConsumerLocation] = React.useState('pickup');
  const [pickupTime, setPickupTime] = React.useState(getStartTime());
  const [appointment, setAppointment] = React.useState({
    pickupTime: getStartTime(),
    consumerPickup: true,
    pickupLocation: null,
    deliveryLocation: null,
    validation: {
      pickupLocation: true,
      deliveryLocation: true,
      ptime: true,
      pdate: true,
      stock: true,
      vin: true,
      make: true,
      model: true,
      year: true,
      color: true,
      cname: true,
      cnumber: true,
      region: true,
      refNum: true,
    },
  });

  // Set customer id to use for lane subscription and to get the customer config
  React.useEffect(() => {
    setCustomerId(parseInt(ctx.customerOverride || ctx.customerId || 0));
  }, [ctx.customerOverride, ctx.customerId]);

  React.useEffect(() => {
    const getCustomerConfig = async () => {
      const config = await queryCustomerConfig(customerId, ctx);
      setCustomerConfig(config);
      if (config && config.appointments && config.appointments.ref_num_label) {
        setRefNumLabel(config.appointments.ref_num_label);
      }
    };

    const getWorkflowSets = async () => {
      const workflowSets = await queryWorkflowSets(customerId);
      setWorkflowSets(workflowSets);
    };

    const getConciergeSla = async () => {
      const res = await ctx.apolloClient.query({
        query: GET_DEFAULT_SLA,
      });
      console.log('sla res', res);
      if (res && res.data && res.data.slas && res.data.slas.length) {
        setConciergeSla(res.data.slas[0]);
      } else {
        setConciergeSla(null);
      }
    };

    if (customerId) {
      getCustomerConfig();
      getWorkflowSets();
      getConciergeSla();
    }
  }, [customerId]);

  //Once we have the workflow set data and customer config, set the default workflow
  React.useEffect(() => {

if (workflowSets.length) {
    if (!workflow || !workflow.id) {
        if (props.appointment && props.appointment.workflow) {
            setWorkflow(props.appointment.workflow);
        } else if (customerConfig && customerConfig.default_workflow_set_id) {
            const configWorkflowSet = workflowSets.find(
                wfs => wfs.id === sdk.utilities.getPropValue(customerConfig, `default_workflow_set_id`)
            );
            if (configWorkflowSet) setWorkflow(configWorkflowSet);
        } else {
            setWorkflow(workflowSets[0]);
        }
    }
}
  }, [workflowSets, customerConfig]);

  // Set the appointment's consumer pickup boolean whenever the consumer location selection changes
  React.useEffect(() => {
    if (consumerLocation === 'pickup') {
      setAppointment(prev => ({
        ...prev,
        consumerPickup: true,
      }));
    } else {
      setAppointment(prev => ({
        ...prev,
        consumerPickup: false,
      }));
    }
  }, [consumerLocation]);

  // Set the appointment's workflow set id based on the workflow selection
  React.useEffect(() => {
    if (workflow && workflow.id) {
      setAppointment(prev => ({
        ...prev,
        workflowSetId: workflow.id,
      }));
    } 
  }, [workflow]);

React.useEffect(() => {
  if (props.appointment) {
    if (props.appointment.config && props.appointment.config.emailsToNotify){
      setEmailsList(props.appointment.config.emailsToNotify);
    } 
    if (props.appointment.lane) {
      setLane(props.appointment.lane);
      setDoesLaneExist(true);
    }
    if (props.appointment.workflow) {
      setWorkflow(props.appointment.workflow);
    }
    if (props.appointment.consumer_at_pickup !== undefined) {
      setAppointment(prev => ({
        ...prev,
        consumerPickup: props.appointment.consumer_at_pickup,
      }));
    }
    if (props.appointment.appointment_time) {
      setPickupTime(moment(props.appointment.appointment_time));
    }

    // Set vehicle data
    setAppointment(prev => ({
      ...prev,
      vehicle: {
        vin: { vehicle_vin: props.appointment.vehicle_vin },
        make: { name: props.appointment.vehicle_make, vehiclemodels: [] },
        model: { name: props.appointment.vehicle_model },
        year: props.appointment.vehicle_year.toString(),
        color: props.appointment.vehicle_color,
        manual: props.appointment.vehicle_manual,
        refNum: props.appointment.config.claim_number,
        stock: null
      },
      // Set consumer data
      consumer: {
        name: props.appointment.consumer_name,
        phone: props.appointment.consumer_phone
      },
      // Set notes
      notes: props.appointment.driver_notes || ''
    }));

    if (props.appointment.appointment_time) {
      setPickupTime(moment(props.appointment.appointment_time));
    }
  }
}, [props.appointment]);

  //////// Input handlers ////////

  const handleFormChange = name => data => {
    log && console.log(`Form change: ${name}`, data);
    setAppointment(prev => ({
      ...prev,
      [name]: data,
    }));
  };

  const handleTimeChange = time => {
    log && console.log(`Time changed to ${time.format()}`);
    setPickupTime(time);
  };

  //////// Validation ////////


  const validateForm = () => {
    // Create a new validation object instead of modifying state directly
    let newValidation = {
      pickupLocation: true,
      deliveryLocation: true,
      ptime: true,
      pdate: true,
      stock: true,
      vin: true,
      make: true,
      model: true,
      year: true,
      color: true,
      cname: true,
      cnumber: true,
      region: true,
      refNum: true,
      phone: true
    };

    try {
      // Check pickup and delivery locations
      if (!appointment.pickupLocation || !appointment.pickupLocation.id) {
        newValidation.pickupLocation = false;
      }
      if (!appointment.deliveryLocation || !appointment.deliveryLocation.id) {
        newValidation.deliveryLocation = false;
      }

      // Check region
      if (
        appointment.pickupLocation &&
        !appointment.pickupLocation.region_id &&
        appointment.deliveryLocation &&
        !appointment.deliveryLocation.region_id
      ) {
        newValidation.region = false;
      }

      // Check vehicle info
      if (!appointment.vehicle || !appointment.vehicle.refNum) {
        newValidation.refNum = false;
      }
      if (!appointment.vehicle || !appointment.vehicle.vin || !appointment.vehicle.vin.vehicle_vin) {
        newValidation.vin = false;
      }
      if (!appointment.vehicle || !appointment.vehicle.make || !appointment.vehicle.make.name) {
        newValidation.make = false;
      }
      if (!appointment.vehicle || !appointment.vehicle.model || !appointment.vehicle.model.name) {
        newValidation.model = false;
      }
      if (!appointment.vehicle || !appointment.vehicle.year) {
        newValidation.year = false;
      }

      // Check pickup time
      if (props.appointment && props.appointment.appointment_time) {
        // For existing appointments - if they're changing the time, it must be at least 2 hours in future
        if (moment(pickupTime).format() !== moment(props.appointment.appointment_time).format()) {
          // They changed the time - enforce 2 hour minimum
          if (moment(pickupTime).diff(moment(), 'minutes') < 120) {
            newValidation.ptime = false;
          }
        }
      } else {
        if (moment(pickupTime).diff(moment(), 'minutes') < 120) {
          console.log('Validation failed: When changing appointment time, it must be at least 2 hours in future', {
            pickupTime: pickupTime,
            currentTime: moment().format()
          });
          newValidation.ptime = false;
        }
      }

      // Check consumer info
      if (!appointment.consumer || !appointment.consumer.name || !appointment.consumer.name.trim()) {
        newValidation.cname = false;
      }
      if (!appointment.consumer || !appointment.consumer.phone || !appointment.consumer.phone.trim()) {
        newValidation.cnumber = false;
      }

      if (appointment.consumer.phone && !validatePhone(appointment.consumer.phone)) {
        newValidation.phone = false;
      }

      // Set all validation results at once
      setAppointment(prev => ({
        ...prev,
        validation: newValidation
      }));
      
      return newValidation;
    } catch (err) {
      console.error('Validation error:', err);
      console.error('Error stack:', err.stack);
      return false;
    }
  };

  const isFormValid = () => {
    const validationResult = validateForm();
    
    try {
      if (!validationResult) {
        return false;
      }

      if (!validationResult.pickupLocation && !validationResult.deliveryLocation) {
        toast.error(`Please select a pickup and delivery location`);
        return false;
      }

      if (!validationResult.pickupLocation) {
        toast.error(`Please select a pickup location`);
        return false;
      }

      if (!validationResult.deliveryLocation) {
        toast.error(`Please select a delivery location`);
        return false;
      }

      if (!validationResult.region) {
        toast.error(`At least one location must be within a Hopdrive service region.`);
        return false;
      }

      if (!appointment.hasOwnProperty('consumerPickup')) {
        return false;
      }

      if (!isConsumerFound(appointment) || !isVehicleFound(appointment)) {
        toast.warning('Please ensure highlighted fields are valid before continuing...');
        return false;
      }

      if (!validationResult.phone) {
        toast.error(`Please enter a valid consumer phone number`);
        return false;
      }

      if (!validationResult.ptime || !validationResult.pdate) {
        toast.error(`Please select an appointment time and date at least 2 hours in the future`);
        return false;
      }
      
    } catch (error) {
      console.error('Form validation crashed', error);
      return false;
    }

    return true;
  };

  const clearForm = () => {
    setAppointment({
      pickupTime: getStartTime(),
      consumerPickup: true,
      pickupLocation: null,
      deliveryLocation: null,
      validation: {
        pickupLocation: true,
        deliveryLocation: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
        region: true,
        refNum: true,
      },
    });
  };

  //////// Handling lanes and locations ////////

  //Upserts a bare-bones version of the lane object with only customer_id, pickup_id, delivery_id and description
  //This is enough to return a lane id that can be inserted along with the move.
  //A hasura event will detect that the lane is not finished and will 'enrich' the lane with its missing data after it has been inserted
  const handleLanes = async (customerId, pickup, delivery) => {
    if (!pickup) {
      return;
    }
    if (!delivery) {
      return;
    }
    if (!pickup.id) {
      return;
    }
    if (!delivery.id) {
      return;
    }
    if (pickup.id === delivery.id) return;
    if (!customerId || customerId < 1) {
      return;
    }
    const lanePairRes = await ctx.sdk.lanes.handleLaneShellPair(
      customerId,
      pickup.id,
      delivery.id,
      pickup.name,
      delivery.name
    );
    if (!lanePairRes.success) {
      console.error('Lane failed to upsert');
      return [null, null];
    }
    if (lanePairRes.success && lanePairRes.laneOne && lanePairRes.laneTwo) {
      log && console.log('lanes upserted successfully');
      if (
        lanePairRes.laneOne.distance_miles === null ||
        lanePairRes.laneOne.dealer_stranded_price === null ||
        lanePairRes.laneOne.dealer_base_price === null
      ) {
        setNewLaneId(lanePairRes.laneOne.id);
      }

      setLanePair([lanePairRes[0], lanePairRes[1]]);
      return [lanePairRes.laneOne, lanePairRes.laneTwo];
    }
    console.error('Unknown error upserting lanes');
    return [null, null];
  };

  const handleLaneChange = async lane => {
    log && console.log('Handle lane change: ', lane);
    if (!lane) return;

    const inboundPickupId = lane && lane.pickup && lane.pickup.id ? lane.pickup.id : 0;
    const inboundDeliveryId = lane && lane.delivery && lane.delivery.id ? lane.delivery.id : 0;

    if (inboundPickupId === 0 || inboundDeliveryId === 0) {
      setPickupDeliveryChange(true);
    } else setPickupDeliveryChange(false);

    setLane(lane);
  };

  const getLaneByLocationIds = async (pickupId, deliveryId, inverseLane = false) => {
    try {
      const newPickupId = inverseLane ? deliveryId : pickupId;
      const newDeliveryId = inverseLane ? pickupId : deliveryId;
      const res = await ctx.apolloClient.query({
        query: GET_LANES_BY_LOCATIONS,
        variables: { pickupId: newPickupId, deliveryId: newDeliveryId },
      });
      if (res.data && res.data.lanes.length > 0) {
        log && console.log(`Found lane:`, res.data.lanes[0]);
        setAppointment(prev => ({
          ...prev,
          pickupLocation: res.data.lanes[0].pickup,
          deliveryLocation: res.data.lanes[0].delivery,
        }));
        return res.data.lanes[0];
      }
    } catch (err) {
      console.error(`Failed to find lane:`, err);
      toast.error(`Failed to find lane`);
    }
  };

  //////// Submission ////////

  const updateAppointment = async appointmentId => {
    log && console.log(`Attempting to update appointment ${appointmentId}...`);
    try {
      // Log the pickupTime before formatting
      log && console.log(`Pickup time before formatting: ${pickupTime}`);

      // Apply the same UTC formatting as in buildInsertableAppointment
      const formattedPickupTime = dayjs(pickupTime).utc().format();
      log && console.log(`Formatted pickup time: ${formattedPickupTime}`);

      // Preserve existing config and merge with new values
      const updatedConfig = {
        ...props.appointment.config,  // Keep existing config values
        claim_number: appointment.vehicle.refNum,
        emailsToNotify: emailsList
      };

      const res = await sdk.gql.mutation(UPDATE_APPOINTMENT, { 
        id: appointmentId,
        changes: {
          pickup_location_id: appointment.pickupLocation.id,
          delivery_location_id: appointment.deliveryLocation.id,
          appointment_time: formattedPickupTime,  // Use formatted pickup time
          workflow_set_id: appointment.workflowSetId,
          consumer_at_pickup: appointment.consumerPickup,
          consumer_name: appointment.consumer.name,
          consumer_phone: appointment.consumer.phone,
          driver_notes: appointment.notes,
          vehicle_year: appointment.vehicle.year,
          vehicle_make: appointment.vehicle.make.name,
          vehicle_model: appointment.vehicle.model.name,
          vehicle_color: appointment.vehicle.color,
          vehicle_vin: appointment.vehicle.vin.vehicle_vin,
          vehicle_manual: appointment.vehicle.manual,
          config: updatedConfig,
          updatedat: 'now()',
          updatedby: getUserEmail()
        }
      });

      if (res && res.success) {
        toast.success(`Appointment updated successfully`);
        return true;
      }
    } catch (err) {
      console.error(`Failed to update appointment:`, err);
      toast.error(`Failed to update appointment`);
    }
    return false;
  };

  const handleFinishAppointment = async () => {
    try {
      let valid = isFormValid();
      if (!valid) {
        return;
      }
  
      setLoading(true);
      if (props.appointment && props.appointment.id) {
        await updateAppointment(props.appointment.id);
        if (props.setEditMode) {
          props.setEditMode(false);
        }
      } else {
        let insertableAppointment = buildInsertableAppointment(appointment, customerId, emailsList, pickupTime);
        if (insertableAppointment) {
          const appointmentId = await insertAppointment(insertableAppointment);
          if (appointmentId) {
            goToRoute(`/appointments/${appointmentId}`);
          }
        }
      }
  
      clearForm();
    } catch (error) {
      console.error('handleFinishAppointment crashed', error);
    }
    setLoading(false);
  };

  const handleSubmitAppointment = () => {
    handleFinishAppointment();
  };

  const handleEditMode = () => {
    props.setEditMode(!props.editMode);
    clearForm();
  };

  //////////////////////////////// RENDER ////////////////////////////////

  return (
    <>
      <div className={cls.root}>
        {ctx && ctx.firebaseUser && (
          <Container maxWidth='lg'>
            <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
              <Grid item md={9} xs={12}>
                <Typography className={cls.head}>{props.editMode ? `Update Appointment` : `Consumer Appointment Planner`}</Typography>
                <Typography className={cls.sub}>
                  {props.editMode ? `Edit your appointment details below.` : `Welcome to the Consumer Appointment Planner. This form allows you to schedule appointments and arrange
                  payment directly with your customer for vehicle pickup and delivery. Once you've submitted the
                  appointment information, your customer will receive an invoice by text message. When paid, the
                  appointment will be accepted and fulfilled by Hopdrive, and you can track its progress from your
                  dashboard.`}
                </Typography>
              </Grid>
              {props.editMode ? (
                <Grid item>
                  <Tooltip
                    placement='top'
                  title={props.editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the move`}
                >
                  <IconButton className={cls.iconBtn} onClick={() => handleEditMode()}>
                    <Icon>{props.editMode ? `lock_open` : `lock`}</Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>

            <div style={{ display: `block` }}>
              <Divide
                spacer
                tip='Choose an origin and destination location to create a lane between the two. If the location is not in our system, you may choose a Google-suggested address and create a new location.'
              >
                Lane
              </Divide>
              <Subscription subscription={GET_LANE_BY_ID} skip={subscriptionSkip} variables={{ id: newLaneId }}>
                {({ loading, error, data }) => {
                  return (
                    <LaneForm
                      defaultLane={props.appointment && props.appointment.lane ? props.appointment.lane : null}
                      validation={lanePair}
                      lane={lane}
                      setLane={setLane}
                      onLaneChange={handleLaneChange}
                      handleLanes={handleLanes}
                      subscriptionSkip={subscriptionSkip}
                      setSubscriptionSkip={setSubscriptionSkip}
                      getLaneByLocationIds={getLaneByLocationIds}
                      customerId={customerId}
                      lanePair={lanePair}
                      setLanePair={setLanePair}
                      doesLaneExist={doesLaneExist}
                      setDoesLaneExist={setDoesLaneExist}
                      type={'concierge'}
                      newLaneId={newLaneId}
                      subscriptionData={data && data.lanes && data.lanes.length > 0 ? data.lanes : null}
                      customerConfig={customerConfig}
                      isSubscriptionRendering={isSubscriptionRendering}
                      setIsSubscriptionRendering={setIsSubscriptionRendering}
                      pickupDeliveryChange={pickupDeliveryChange}
                      appointment={true}
                    />
                  );
                }}
              </Subscription>
            </div>

            <div style={{ display: `block` }}>
              <Divide spacer tip="Fill out the consumer's contact and vehicle information.">
                Consumer Location & Vehicle Information
              </Divide>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <div className={cls.paperWhite}>
                    <>
                      <ConsumerLocButtonGroup
                        consumerLocation={consumerLocation}
                        setConsumerLocation={setConsumerLocation}
                        switchLabelA={`Picking Up From Customer`}
                        switchLabelB={`Delivering To Customer`}
                      />
                    </>

                    <div className={cls.break} />

                    <WorkflowForm
                      workflowSets={workflowSets}
                      workflow={workflow}
                      onWorkflowChange={setWorkflow}
                      isConcierge={true}
                      conciergeSla={conciergeSla}
                    />

                    <div className={cls.break} />

                    <DatetimeSelect
                      validation={appointment.validation}
                      onChange={handleTimeChange}
                      timeData={pickupTime}
                      tip={`The pickup time for the customer's location should be based on the appointment time scheduled with your customer. Note that the pickup time is not exact and may differ from 5-10 minutes.`}
                      useUtc
                      getDefaultStartTime={getStartTime}
                      appointment={true}
                    />

                    <div className={cls.break} />

                    <ConsumerInfoForm
                      validation={appointment.validation}
                      onChange={handleFormChange('consumer')}
                      consumerData={appointment.consumer}
                    />

                    <div className={cls.break} />

                    <NotesInput onChange={handleFormChange('notes')} defaultNotes={props.appointment && props.appointment.driver_notes ? props.appointment.driver_notes : ''} />
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.paperGray}>
                    <VehicleForm
                      validation={appointment.validation}
                      onChange={handleFormChange('vehicle')}
                      customerId={customerId}
                      appointmentMode={true}
                      refNumLabel={refNumLabel ? refNumLabel : null}
                      vehicleData={appointment.vehicle}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div>
              <Divide
                spacer
                tip='Add up to 5 email addresses to receive a copy of the appointment offer.'
              >
                Email Notifications
              </Divide>
              <NotificationManagement
                emailsList={emailsList}
                setEmailsList={setEmailsList}
                editMode={true}
                type={'appointment'}
                appointment={appointment}
              />
            </div>

            <div className={cls.actions}>
              <Button
                className={cls.action}
                data-testid='submit-appointment-button'
                variant='outlined'
                color='primary'
                size='large'
                onClick={() => handleSubmitAppointment()}
              >
                Submit Appointment
              </Button>
            </div>
          </Container>
        )}
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paperWhite: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  paperGray: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: '18px',
    },
  },
  sub: {
    marginBottom: '-16px',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-12px',
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-8px',
      fontSize: '12px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  block: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '56px',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////////////////////// GQL ////////////////////////////////////////

const GET_LANE_BY_ID = gql`
  subscription get_lanes_by_id($id: bigint!) {
    lanes(where: { id: { _eq: $id } }) {
      id
      customer_id
      dealer_base_price
      dealer_stranded_price
      distance_miles
    }
  }
`;

const GET_LANES_BY_LOCATIONS = gql`
  query get_lanes_by_locations($pickupId: bigint!, $deliveryId: bigint!) {
    lanes(
      where: { origin_location_id: { _eq: $pickupId }, destination_location_id: { _eq: $deliveryId } }
      order_by: [{ favorite: desc }, { description: asc }]
    ) {
      ...Lane
    }
  }
  ${fragments.lane}
`;

const GET_CUSTOMER_CONFIG = gql`
  query get_customer_config($customerId: bigint!) {
    customers(where: { id: { _eq: $customerId } }) {
      id
      config
      organization {
        id
        config
      }
    }
  }
`;

const INSERT_APPOINTMENT = gql`
  mutation insert_appointment($appointment: appointments_insert_input!) {
    insert_appointments_one(object: $appointment) {
      id
    }
  }
`;

const GET_WORKFLOW_SETS = gql`
  query get_workflowsets_appointment_planner($customerId: bigint!) {
    workflowsets(
      where: {
        _or: [
          { customer_id: { _eq: $customerId } }
          { organization: { customers: { id: { _eq: $customerId } } } }
          { public: { _eq: true } }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      description
      pickup_workflow_id
      delivery_workflow_id
      public
      sla {
        id
        description
        duration_hrs
        label
        name
        public
      }
    }
  }
`;

const UPDATE_APPOINTMENT = gql`
  mutation update_appointment($id: bigint!, $changes: appointments_set_input!) {
    update_appointments_by_pk(
      pk_columns: { id: $id }
      _set: $changes
    ) {
      id
    }
  }
`;

const GET_DEFAULT_SLA = gql`
  query get_default_sla{
    slas(where: { name: { _eq: "default_concierge" } }) {
      id
      duration_hrs
      label
    }
  }
`;
