import React from 'react';
import gql from 'graphql-tag';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';
import { toast } from 'react-toastify';
import { getUserEmail } from '../../utils/authHelper';
import sdk from '@hopdrive/sdk';

////////// COMPONENT //////////
export default function AppointmentCancelModal(props) {

  const { open, onClose, appointment } = props;

  const [subtitleText, setSubtitleText] = React.useState('');

  React.useEffect(() => {
    if (appointment) {
        if (appointment.status === 'paid' && appointment.move_id) {
            setSubtitleText(`Are you sure you want to cancel this appointment? Move #${appointment.move_id} will be canceled and the customer's payment will be refunded.`);
        } else if (appointment.status === 'paid' && !appointment.move_id) {
            setSubtitleText(`Are you sure you want to cancel this appointment? No move will be created, and the customer's payment will be refunded.`);
        } else {
            setSubtitleText(`Are you sure you want to cancel this appointment?`);
        }
    }
  }, [appointment]);

  const handleCancelAppointment = async () => {
    try {
      const res = await sdk.gql.mutation(CANCEL_APPOINTMENT, {
        id: appointment.id,
        updatedby: getUserEmail(),
      });
      if (res && res.success) {
        toast.success('Appointment canceled successfully!');
      } else {
        toast.error('Failed to cancel appointment!');
      }
    } catch (err) {
      console.error('Failed to cancel appointment:', err);
      toast.error('Failed to cancel appointment!');
    }
  };

  const handleSubmit = async () => {
    await handleCancelAppointment();

    handleClose();
  }

  const handleClose = () => {
    if (onClose) onClose();
  }

  return (<>
    <Modal open={open}>
      
      <ModalHeader handleClose={handleClose}>Cancel Appointment</ModalHeader>

      <ModalContent subtitle={subtitleText} />

      <ModalFooter>
        <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
        <ModalAction onClick={() => handleClose()} color="secondary">Go Back</ModalAction>
      </ModalFooter>

    </Modal>
  </>)
}

////////// GRAPHQL //////////
const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($id: bigint!, $updatedby: String) {
    update_appointments(
      where: { id: { _eq: $id } }
      _set: { status: "canceled", updatedby: $updatedby, updatedat: "now()" }
    ) {
      affected_rows
    }
  }
`;