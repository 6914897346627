//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

//////////////////////// COMPONENT ////////////////////////

function MoveTimeModal(props) {
  const cls = useStyles();

  const { open, onClose, input } = props;

  const [myReadyByDate, setMyReadyByDate] = React.useState(``);
  const [myReadyByTime, setMyReadyByTime] = React.useState(``);

  React.useEffect(() => {
    if (input) {
      setMyReadyByDate(input.readyBy);
      setMyReadyByTime(input.readyBy);
    }
  }, [input]);

  const handleDateChange = str => event => {
    if (str === `pickup`) setMyReadyByDate(dayjs(event).startOf(`day`).format());
  };

  const handleTimeChange = str => event => {
    if (str === `pickup`) setMyReadyByTime(dayjs(event).format());
  };

  const handleSubmit = async () => {
    let newReadyBy = `${dayjs.utc(myReadyByDate).format(`YYYY-MM-DD`)}T${dayjs
      .utc(myReadyByTime)
      .format(`HH:mm`)}:00+00:00`;

    // Check that new time is at least 90 minutes after current time
    if (dayjs(newReadyBy).isBefore(dayjs().add(90, `minutes`))) {
      toast.error(`New time must be at least 90 minutes after current time`);
      return;
    }

    handleClose({
      newReadyBy: newReadyBy,
      oldReadyBy: input.readyBy,
    });
  };

  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Times</ModalHeader>

        <ModalContent subtitle={`Edit the time at which the move will be ready.`}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography className={cls.titleTxt}>Ready By Time</Typography>

            <Grid container spacing={2} alignItems='center'>
              <Grid item xs>
                <DatePicker
                  autoOk
                  fullWidth
                  minDate={new Date()}
                  variant='inline'
                  orientation='portrait'
                  format='MM/dd/yyyy'
                  label='Ready By Date'
                  size='small'
                  inputVariant='outlined'
                  value={myReadyByDate}
                  onChange={handleDateChange(`pickup`)}
                />
              </Grid>

              <Grid item xs>
                <TimePicker
                  autoOk
                  fullWidth
                  minDate={new Date()}
                  minutesStep={5}
                  variant='inline'
                  orientation='portrait'
                  label='Ready By Time'
                  size='small'
                  inputVariant='outlined'
                  value={myReadyByTime}
                  onChange={handleTimeChange(`pickup`)}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()} color='primary'>
            Save Times
          </ModalAction>

          <ModalAction onClick={() => handleClose()}>Cancel</ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  titleTxt: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 500,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveTimeModal;
