//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Chip,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { useUserDetails } from '../UserDetails/UserDetailsProvider';
import ReusableMultiselect from '../../reusable/ReusableMultiselect/ReusableMultiselect';

import AvatarUpload from '../UserDetails/AvatarUpload';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function UserAddForm() {
  const cls = useStyles();
  const ctx = useUserDetails();

  React.useEffect(() => {
    if (!ctx.customerId && ctx.adminCustomerId) {
      ctx.setCustomerId(ctx.adminCustomerId);
    }
  }, [ctx.adminCustomerId]);
  //handle allowed customers for dealer-admin role
  React.useEffect(() => {
    // Skip if not dealer-admin role
    if (!ctx.customerId) return;
    if (!ctx.customers || !ctx.customers.length) return;
    if (ctx.defaultRole !== 'dealer-admin') return;

    let selectedIds = [];

    // Handle existing allowed customers case
    if (ctx.allowedCustomerIds && ctx.allowedCustomerIds.length > 0) {
      // Convert string IDs to numbers
      selectedIds = ctx.allowedCustomerIds.map(id => Number(id));
    }

    // Add default customer if not already included
    if (!selectedIds.includes(Number(ctx.customerId))) {
      selectedIds.push(Number(ctx.customerId));
      const defaultCustomer = ctx.customers.find(c => c.id === Number(ctx.customerId));
      if (defaultCustomer) {
        ctx.setAllowedCustomers(prev => [...(prev || []), defaultCustomer]);
      }
    }

    // Update allowed customer IDs
    ctx.setAllowedCustomerIds(selectedIds);
  }, [ctx.defaultRole, ctx.customerId, ctx.customers]);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleSelectedCustomers = selections => {
    ctx && ctx.setAllowedCustomers(selections);
  };

  const handleRemoveCustomer = index => {
    ctx &&
      ctx.setAllowedCustomers(prevCustomers => {
        return prevCustomers.filter((_, i) => i !== index);
      });

    ctx.setAllowedCustomerIds(prevIds => {
      const updatedIds = [...prevIds];
      updatedIds.splice(index, 1);
      return updatedIds;
    });
  };

  return (
    <Container maxWidth='lg'>
      {/* VITALS */}
      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Display Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx.isSaving}
              required
              fullWidth
              multiline
              label='Display Name'
              placeholder='Enter display name...'
              variant='outlined'
              size='small'
              value={ctx.displayName}
              onChange={handleInputChange(ctx.setDisplayName)}
              error={ctx && ctx.validation && ctx.validation.displayName === false}
              helperText={
                ctx && ctx.validation && ctx.validation.displayName && ctx.validation.displayName === false
                  ? `A display name is required!`
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      person
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Email <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx.isSaving}
              required
              fullWidth
              multiline
              label='Email'
              placeholder='Enter email address...'
              variant='outlined'
              size='small'
              value={ctx.email}
              onChange={handleInputChange(ctx.setEmail)}
              error={ctx && ctx.validation && ctx.validation.email && ctx.validation.email === false}
              helperText={
                ctx && ctx.validation && ctx.validation.email === false ? `A valid email address is required!` : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      mail
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Phone (Optional)</Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx.isSaving}
              fullWidth
              multiline
              label='Phone'
              placeholder='Enter phone number...'
              variant='outlined'
              size='small'
              value={ctx.phone}
              onChange={handleInputChange(ctx.setPhone)}
              error={ctx && ctx.validation && ctx.validation.phone && ctx.validation.phone === false}
              helperText={
                ctx && ctx.validation && ctx.validation.phone && ctx.validation.phone === false
                  ? `Phone number is invalid!`
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      phone
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      {/* DEFAULT CUSTOMER */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Default Rooftop <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              The main rooftop with which the user is associated. This field is required for all dealer users. If you
              are creating a user with the dealer super admin role, they will automatically receive permissions for all
              rooftops within the organization.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.customers &&ctx.customers.length > 0 ? (
              <TextField
                disabled={ctx.isSaving}
                select
                fullWidth
                label='Default Rooftop'
                placeholder='Select the default rooftop...'
                variant='outlined'
                size='small'
                value={ctx.customerId}
                onChange={handleInputChange(ctx.setCustomerId)}
                error={ctx && ctx.validation && ctx.validation.customerId && ctx.validation.customerId === false}
                helperText={
                  ctx && ctx.validation && ctx.validation.customerId && ctx.validation.customerId === false
                    ? 'Default rooftop is required!'
                    : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        roofing
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                {ctx.customers.map(c => (
                  <MenuItem key={`organization-${c.id}`} value={c.id}>
                    {c.name} (#{c.id})
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </div>
        </Grid>
      </Grid>

      {/* ROLE */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              User Role <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <>
              <Typography className={cls.sectionSubtitleTxt}>
                Select a default role for the user, which will determine their level of access to the organization's
                rooftops.
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                <b>Super Admin</b> - This role will has full, automatic access to all rooftops within an organization.
                It can also manage users and other super admins.
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                <b>Admin</b> - This user will have access to the subset of rooftops that you will be prompted to
                specify.
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                <b>User</b> - This role will have access to a single rooftop that you will be prompted to specify.
              </Typography>
            </>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              disabled={ctx.isSaving}
              required
              select
              fullWidth
              label='Role'
              placeholder='Select a role...'
              variant='outlined'
              size='small'
              value={ctx.defaultRole}
              onChange={handleInputChange(ctx.setDefaultRole)}
              error={ctx && ctx.validation && ctx.validation.defaultRole && ctx.validation.defaultRole === false}
              helperText={
                ctx && ctx.validation && ctx.validation.defaultRole && ctx.validation.defaultRole === false
                  ? 'Select a default role!'
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      category
                    </Icon>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value='dealer-super-admin'>Super Admin</MenuItem>
              <MenuItem value='dealer-admin'>Admin</MenuItem>
              <MenuItem value='dealer'>User</MenuItem>
            </TextField>
          </div>
        </Grid>
      </Grid>

      {/* ALLOWED CUSTOMERS */}

      {ctx && ctx.defaultRole && ctx.defaultRole === 'dealer-admin' && (
        <Grid container wrap='nowrap'>
          <Grid item xs={titleGridWidth}>
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>
                Allowed Rooftops <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                Admin users must be given access to at least one rooftop in addition to their default.
              </Typography>
            </div>
          </Grid>

          <Grid item>
            <div className={cls.sectionLine} />
          </Grid>

          <Grid item xs>
            <div className={cls.sectionR}>
              <ReusableMultiselect
                dataArray={ctx.customers}
                selectedArray={ctx.allowedCustomerIds || []}
                optionName={'name'}
                label={'Allowed Rooftops'}
                handleOutput={handleSelectedCustomers}
              />

              <Spacer />
              {ctx &&
                ctx.allowedCustomers &&
                ctx.allowedCustomers.length &&
                ctx.allowedCustomers.map((customer, index) => (
                  <Chip
                    key={index}
                    style={{ margin: 5 }}
                    label={customer.name}
                    onDelete={() => handleRemoveCustomer(index)}
                  />
                ))}
              {ctx &&
                ctx.validation &&
                ctx.validation.allowedCustomers &&
                ctx.validation.allowedCustomers === false && (
                  <Typography variant='body2' color='error'>
                    Admins must have access to at least one rooftop in addition to their default.
                  </Typography>
                )}
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  titleChipIcon: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5, 0, 0),
  },
}));
