//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { useInvoices } from './useInvoices';

//////////////////////// COMPONENT ////////////////////////

export default function InvoicePayments({ invoice }) {
  const cls = useStyles();
  const { capFirst, getMonetaryValue } = useTools();
  const { getPaidTotalFromARPayments } = useInvoices();

  const paidTotal = getPaidTotalFromARPayments(invoice.arpayments);

  if (invoice.arpayments && invoice.arpayments.length > 0) {
    return (
      <>
        <div className={cls.paper}>
          <Typography className={cls.titleTxt}>Payment History</Typography>

          <Spacer />

          <Grid container spacing={1} wrap='nowrap'>
            <Grid item xs={1} className={cls.gridItem}>
              <Typography align='left' className={cls.payKey}>
                ID
              </Typography>
            </Grid>
            <Grid item xs={2} className={cls.gridItem}>
              <Typography align='left' className={cls.payKey}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={4} className={cls.gridItem}>
              <Typography align='left' className={cls.payKey}>
                Transaction&nbsp;ID
              </Typography>
            </Grid>
            <Grid item xs={2} className={cls.gridItem}>
              <Typography align='right' className={cls.payKey}>
                Status
              </Typography>
            </Grid>
            <Grid item xs={3} className={cls.gridItem}>
              <Typography align='right' className={cls.payKey}>
                Amount
              </Typography>
            </Grid>
          </Grid>

          <div className={cls.lineBreak} />

          {invoice.arpayments.map((payment, i) => (
            <React.Fragment key={'paymentRow' + payment.id}>
              <Grid container spacing={1} wrap='nowrap'>
                <Grid item xs={1} className={cls.gridItem}>
                  <Typography align='left' className={cls.payVal}>
                    {payment.id}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={cls.gridItem}>
                  <Typography align='left' className={cls.payVal}>
                    {dayjs(payment.createdat).format(`MM/DD/YYYY`)}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={cls.gridItem}>
                  <Typography align='left' className={cls.payVal}>
                    {payment.gateway_transaction_id || `-`}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={cls.gridItem}>
                  <Typography align='right' className={cls.payVal}>
                    {capFirst(payment.status)}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={cls.gridItem}>
                  <Typography align='right' className={cls.payVal}>
                    {getMonetaryValue(payment.amount)}
                  </Typography>
                </Grid>
              </Grid>
              {i < invoice.arpayments.length - 1 ? <div className={cls.lineBreak} /> : null}
            </React.Fragment>
          ))}

          <Spacer size='lg' />

          <Grid container spacing={0}>
            <Grid item xs>
              <Typography className={cls.totalKey}>Actual Paid Total (May include processing fee):</Typography>
            </Grid>
            <Grid item>
              <Typography className={cls.totalVal}>{getMonetaryValue(paidTotal)}</Typography>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
  return null;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  totalKey: {
    verticalAlign: 'top',
    marginTop: '1px',
    marginRight: theme.spacing(2),
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
    },
  },
  totalVal: {
    verticalAlign: 'top',
    lineHeight: 1,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  gridItem: {
    minWidth: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  payKey: {
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  payVal: {
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.dividerStrong,
  },
}));
