import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { getUserEmail, getUserToken } from '../../utils/authHelper';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  GET_ALLOWED_CUSTOMERS,
  GET_ALLOWED_PAYER_PAYEES,
  GET_ROLETYPES,
  GET_ALLOWED_ROLES,
  GET_CUSTOMERS,
  UPSERT_ALLOWED_CUSTOMERS,
  UPSERT_ALLOWED_ROLES,
  SAVE_USER_DETAILS,
  TERMINATE_USER,
  REINSTATE_USER,
  CREATE_USER,
  REMOVE_ALLOWED_CUSTOMER,
  REMOVE_ALLOWED_ROLE,
} from './gql';
import { useData } from '../../../DataProvider';
import { getCustomerId } from '../../utils/authHelper';

const log = true;

const UserDetailsContext = React.createContext({});

function UserDetailsProvider({ children, user = {}, refetch }) {
  const { apolloClient, customerOverride } = useData();

  // Base State
  const [editMode, setEditMode] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [adminEmail, setAdminEmail] = React.useState(null);
  const [validation, setValidation] = React.useState({});
  const [adminCustomerId, setAdminCustomerId] = React.useState(null);

  // User Fields
  const [active, setActive] = React.useState();
  const [displayName, setDisplayName] = React.useState();
  const [email, setEmail] = React.useState();
  const [defaultRole, setDefaultRole] = React.useState();
  const [avatarUrl, setAvatarUrl] = React.useState();
  const [updatedBy, setUpdatedBy] = React.useState();
  const [phone, setPhone] = React.useState();
  const [userId, setUserId] = React.useState();

  // For building Hasura claims
  const [roles, setRoles] = React.useState();
  const [customers, setCustomers] = React.useState();
  const [allowedCustomers, setAllowedCustomers] = React.useState();
  const [customerId, setCustomerId] = React.useState();
  const [payers, setPayers] = React.useState();
  const [payees, setPayees] = React.useState();
  const [originalRoleIds, setOriginalRoleIds] = React.useState();
  const [originalCustomerIds, setOriginalCustomerIds] = React.useState();
  const [originalCustomers, setOriginalCustomers] = React.useState();
  const [allowedRegionIds, setAllowedRegionIds] = React.useState();
  const [allowedRoleIds, setAllowedRoleIds] = React.useState();
  const [allowedCustomerIds, setAllowedCustomerIds] = React.useState();

  //Get the acting user's email for event logging and customer ID for setting the default rooftop and fetching customers
  const getAdminCustomerId = async () => {
    const adminRooftop = await getCustomerId();
    setAdminCustomerId(adminRooftop);
  }

  React.useEffect(() => {
    let loggedInUserEmail = getUserEmail();
    setAdminEmail(loggedInUserEmail);
    getAdminCustomerId();
  }, [])

  React.useEffect(() => {
    if ((!customers || !customers.length) && adminCustomerId) fetchCustomers(adminCustomerId);
  }, [adminCustomerId]);

  //Reset the state when edit mode turned off
  React.useEffect(() => {
    if (!editMode) {
      if (user) {
          setActive(user.active || false);
          setDisplayName(user.display_name || null);
          setEmail(user.email || null);
          setDefaultRole(user.default_role || null);
          setAvatarUrl(user.avatar_url || null);
          setPhone(user.phone || null);
          setUserId(user.id || null);
          setCustomerId(user.customer_id || null);
          if (originalCustomerIds && originalCustomerIds.length && originalCustomerIds.length > 0) setAllowedCustomerIds(originalCustomerIds);
          if (originalCustomers && originalCustomers.length && originalCustomers.length > 0) setAllowedCustomers(originalCustomers);
      } else {
        setActive(null);
        setDisplayName(null);
        setEmail(null);
        setDefaultRole(null);
        setAvatarUrl(null);
        setPhone(null);
        setUserId(null);
        setCustomerId(customerOverride || adminCustomerId || null);
      }
    }
  }, [editMode])

  //Get the email of the current logged in user who's doing the editing, which will eventually be used to populate the created_by/updated_by fields.
  //Also get the base lists of regions, customers, and roles to be used as selection options
  React.useEffect(() => {

    if (!roles || !roles.length) {
      fetchRoles();
    } else {
      if (defaultRole && defaultRole === 'dealer') {
        const dealerRoleId = roles.find(r => r.name === 'dealer').id;
        setAllowedRoleIds([dealerRoleId]);
          setAllowedCustomerIds([])
          setAllowedCustomers([])
      } else if (defaultRole && defaultRole === 'dealer-admin') {
        setAllowedRoleIds([roles.find(r => r.name === 'dealer-admin').id]);
      } else if (defaultRole && defaultRole === 'dealer-super-admin') {
        const dealerSuperAdminRoleId = roles.find(r => r.name === 'dealer-super-admin').id;
        const dealerAdminRoleId = roles.find(r => r.name === 'dealer-admin').id;
        setAllowedRoleIds([dealerSuperAdminRoleId, dealerAdminRoleId]);
      }
    }
  }, [defaultRole, roles]);

  const fetchRoles = async () => {
    try {
      const res = await apolloClient.query({ query: GET_ROLETYPES });
      const newRoletypes = getPropValue(res, `data.roletypes`);
      if (newRoletypes && newRoletypes.length) {
        setRoles(newRoletypes);
      }
    } catch (err) {
      console.log('error fetching roles', err)
    }
  };

  const fetchCustomers = async (customerId) => {
    const res = await apolloClient.query({ query: GET_CUSTOMERS, variables: {customerId} });
    const newCustomers = res && res.data && res.data.customers && res.data.customers.length && res.data.customers.length > 0 && res.data.customers[0].organization && res.data.customers[0].organization.customers ? res.data.customers[0].organization.customers : null;
    if (newCustomers && newCustomers.length) {
      setCustomers(newCustomers);
    }
  };

  // Set state on user upon receiving prop
  React.useEffect(() => {
    if (user) {
      setActive(user.active || false);
      setDisplayName(user.display_name || null);
      setEmail(user.email || null);
      setDefaultRole(user.default_role || null);
      setAvatarUrl(user.avatar_url || null);
      setPhone(user.phone || null);
      setUserId(user.id || null);
      setCustomerId(user.customer_id || null);
    }
    if (user.id) {
      fetchAllowedRoles(user.id);
      fetchAllowedCustomers(user.id);
    }
  }, [user]);

  const fetchAllowedRoles = async userId => {
    let originalRoles = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_ROLES, variables: { userId } });
    const userToRoles = getPropValue(res, `data.usertoroles`);
    if (userToRoles && userToRoles.length > 0) {
      //Store initial allowed roles so that later we can check if one has been removed and we need to delete it
      userToRoles.forEach(role => {
        originalRoles.push(role.role_id);
      });
      setOriginalRoleIds(originalRoles);
    }
  };

  const fetchAllowedCustomers = async userId => {
    let originalCustomersList = [];
    const res = await apolloClient.query({ query: GET_ALLOWED_CUSTOMERS, variables: { userId } });
    const userToCustomers = getPropValue(res, `data.usertocustomers`);
    if (userToCustomers && userToCustomers.length > 0) {
      setAllowedCustomers(userToCustomers);
      setOriginalCustomers(userToCustomers);
      userToCustomers.forEach(customer => {
        originalCustomersList.push(customer.customer_id);
      });
      setOriginalCustomerIds(originalCustomersList);
      setAllowedCustomerIds(originalCustomersList)
    }
  };

//   const fetchAllowedPayees = async customerId => {
//     let allowedPayees = [];
//     const res = await apolloClient.query({ query: GET_ALLOWED_PAYER_PAYEES, variables: { customerId } });
//     const payerToCustomer = getPropValue(res, `data.payertocustomer`);
//     if (payerToCustomer && payerToCustomer.length > 0) {
//       payerToCustomer.forEach(p => {
//         if (p.payer_id === customerId) {
//           allowedPayees.push(p);
//         }
//       });
//     }
//     setPayees(allowedPayees);
//   };

//   const fetchAllowedPayers = async customerId => {
//     let allowedPayers = [];
//     const res = await apolloClient.query({ query: GET_ALLOWED_PAYER_PAYEES, variables: { customerId } });
//     const payerToCustomer = getPropValue(res, `data.payertocustomer`);
//     if (payerToCustomer && payerToCustomer.length > 0) {
//       payerToCustomer.forEach(p => {
//         if (p.payee_id === customerId) {
//           allowedPayers.push(p);
//         }
//       });
//     }
//     setPayers(allowedPayers);
//   };

  //Generate a comparison between old and new permissions
  const getPermissionsDelta = () => {
    const removedCustomers = findRecordsToRemove(originalCustomerIds, allowedCustomerIds);
    const addedCustomers = findRecordsToAdd(originalCustomerIds, allowedCustomerIds);

    const removedRoles = findRecordsToRemove(originalRoleIds, allowedRoleIds);
    const addedRoles = findRecordsToAdd(originalRoleIds, allowedRoleIds);

    const permissionsDelta = {}
    if (removedCustomers.length > 0) permissionsDelta.removedCustomers = removedCustomers;
    if (addedCustomers.length > 0) permissionsDelta.addedCustomers = addedCustomers;
    if (removedRoles.length > 0) permissionsDelta.removedRoles = removedRoles;
    if (addedRoles.length > 0) permissionsDelta.addedRoles = addedRoles;

    return permissionsDelta;
  }

  const findRecordsToAdd = (original, current) => {
    let newIds = [];
    current && current.length > 0 && current.forEach(c => {
      if (original && original.length > 0 && !original.includes(c)) {
        newIds.push(c);
      }
    });
    return newIds;
  }

  const writeUserEventlog = async (email, userId, action, metadata) => {
    const permissionsDelta = metadata ? getPermissionsDelta() : null;
    const token = await getUserToken();
      try {
        const eventlogRes = await axios({
          method: `POST`,
          url: `/.netlify/functions/write-user-event-logs`,
          data: {
            actorEmail: email,
            action: action,
            affectedUserId: userId,
            metadata: permissionsDelta,
          },
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        })
        if (eventlogRes && eventlogRes.status && eventlogRes.status !== 200) {
          console.log('Failed to write user eventlog:', eventlogRes);
          Sentry.captureException(`Failed to write user eventlog: ${eventlogRes}`);
        }
      } catch (err) {
        console.error(`Failed to write user eventlog:`, err);
        Sentry.captureException(`Failed to write user eventlog: ${err}`);
      }
    }

  //Determine if any allowances were removed during the editing session
  const findRecordsToRemove = (original, current) => {
    let missingIds = [];
    original && original.length > 0 && original.forEach(o => {
      if (current && current.length > 0 && !current.includes(o)) {
        missingIds.push(o);
      } else if (!current || current.length === 0) {
        missingIds.push(o);
      }
    });
    return missingIds;
  };


  const validateForm = () => {
    let valid = true;

    const isValidDisplayName = username => {
      if (!username || username.length < 2) {
        return false;
      } else return true;
    };

    const isValidEmail = email => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (email) return emailPattern.test(email.toLowerCase());
      else return false;
    };

    const isValidUrl = url => {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlPattern.test(url);
    };

    const isValidPhoneNumber = phoneNumber => {
      const digitsOnly = phoneNumber.replace(/\D/g, '');
      const phonePattern = /^\d{10}$/;

      return phonePattern.test(digitsOnly);
    };

    const isCustomersValid = (allowedCustomers) => {
      if (defaultRole && (defaultRole === 'dealer-admin')) {
        if (!allowedCustomers || !allowedCustomers.length || allowedCustomers.length < 2) {
          return false
        }
      }
      return true
    }

    const isCustomerValid = (customerId) => {
      if (defaultRole && (defaultRole === 'dealer-admin' || defaultRole === 'dealer' || defaultRole === 'dealer-super-admin')) {
        if (!customerId) {
          return false
        }
      }
      return true
    }

    const newValidation = {
      displayName: isValidDisplayName(displayName),
      email: isValidEmail(email),
      avatar: avatarUrl ? isValidUrl(avatarUrl) : true,
      phone: phone ? isValidPhoneNumber(phone) : true,
      defaultRole: defaultRole ? true : false,
      customer: isCustomerValid(customerId),
      customers: isCustomersValid(allowedCustomers)
    };

    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return {valid: valid, validation: newValidation};
  };

  //If the form is valid: 
  // 1) Remove allowances as needed
  // 2) Insert new allowances
  // 3) Update the user record, which additionally will trigger a build of Firebase claims based on the allowances
  const handleSave = async () => {
    setIsSaving(true);

    const formValidation = validateForm();

    if (!formValidation.valid) {
      if (formValidation.validation.customers === false) {
        toast.error(`Dealer-admins must have at least one allowed rooftop in addition to the default rooftop.`);
      } else if (
        formValidation.validation.defaultRole === false ||
        formValidation.validation.email === false ||
        formValidation.validation.avatar === false ||
        formValidation.validation.phone === false ||
        formValidation.validation.customer === false ||
        formValidation.validation.displayName === false
        ) {
        toast.error(`Failed to save. Please check the form for errors.`);
      } 
    } else {
      let customersToRemove;
      let rolesToRemove;

      const eventlogRes = await writeUserEventlog(adminEmail, userId, 'user.permissions.updated', true);

      if (
        allowedRoleIds &&
        allowedRoleIds.length &&
        allowedRoleIds.length > 0 &&
        originalRoleIds &&
        originalRoleIds.length > 0
      ) {
        rolesToRemove = findRecordsToRemove(originalRoleIds, allowedRoleIds);
      }

      if (
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        originalCustomerIds &&
        originalCustomerIds.length > 0
      ) {
        customersToRemove = findRecordsToRemove(originalCustomerIds, allowedCustomerIds);
      }

      try {

        if (rolesToRemove && rolesToRemove.length > 0) {
          for (let i = 0; i < rolesToRemove.length; i++) {
            const res = await apolloClient.mutate({ mutation: REMOVE_ALLOWED_ROLE, variables: { roleId: rolesToRemove[i], userId: userId } });
          }
        }

        if (customersToRemove && customersToRemove.length > 0) {
          for (let i = 0; i < customersToRemove.length; i++) {
            const res = await apolloClient.mutate({
                mutation: REMOVE_ALLOWED_CUSTOMER,
                variables: { customerId: customersToRemove[i], userId: userId },
              });
          }
        }
      } catch (err) {
        console.log('Error removing allowances', err);
      }

      const allowedCustomersObj =
        allowedCustomers &&
        allowedCustomers.length &&
        allowedCustomers.length > 0 &&
        allowedCustomers.map(c => {
          return {
            customer_id: c.customer_id || c.id,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

      const allowedRolesObj =
        allowedRoleIds &&
        allowedRoleIds.length &&
        allowedRoleIds.length > 0 &&
        allowedRoleIds.map(r => {
          return {
            role_id: r,
            user_id: user.id,
            updated_at: 'now()',
            updated_by: adminEmail,
            created_at: 'now()',
            created_by: adminEmail,
          };
        });

        try {
            if (allowedCustomersObj) {
                const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_CUSTOMERS, variables: { allowedCustomersObj: allowedCustomersObj } });
            }
            if (allowedRolesObj) {
                const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_ROLES, variables: { allowedRolesObj: allowedRolesObj } });
            }
          } catch (err) {
            console.log('Error upserting new allowances:', err);
            toast.error('Failed to update allowances. User permissions could not be rebuilt');
            setIsSaving(false);
          }

      try {
        const saveUserRes = await handleSaveUserChanges(user.id);
        setIsSaving(false);
        if (refetch) {
          await refetch();
          await fetchAllowedCustomers(user.id);
        }
      } catch (err) {
        console.log('Failed to update user', err);
        setIsSaving(false);
      }
    }
  };

  const handleSaveUserChanges = async (userId) => {
    setIsSaving(true);

    try {
      const variables = {
        userId: userId || null,
        userObj: {
          active: active || true,
          email: email ? email.trim().toLowerCase() : null,
          phone: phone ? phone.trim() : null,
          avatar_url: avatarUrl ? avatarUrl : null,
          default_role: defaultRole ? defaultRole : null,
          display_name: displayName ? displayName : null,
          updated_at: 'now()',
          customer_id: customerId ? customerId : null,
        },
      };

      const res = await apolloClient.mutate({mutation: SAVE_USER_DETAILS, variables: variables });
      if (res && res.data) {
        if (getPropValue(res, `data.update_users.affected_rows`) > 0) {
          log && console.log(`Successfully updated user record:`, res.data.update_users);
          toast.success(`Successfully updated User!`);
          
          //The update user --> upsert permissions --> update user again timing is pretty delicate, and there's a timing problem such that requerying for allowed customers/regions will still return the original list and not the updated one.
          //So, we'll just set the state to the new values we just saved.

          let newCustomers = res.data.update_users.returning && res.data.update_users.returning.length ? res.data.update_users.returning[0].users_usertocustomers : null;

          setAllowedCustomers(newCustomers);
          setOriginalCustomers(newCustomers);
          let newCustomerIds = newCustomers && newCustomers.length ? newCustomers.map(c => c.customer_id) : [];
          setAllowedCustomerIds(newCustomerIds);
          setOriginalCustomerIds(newCustomerIds);

          setEditMode(false);
          setIsSaving(false);
          return true
        } else {
          toast.error(`Failed to update user!`);
          console.error(`Failed to update user!`);
          setEditMode(false);
          setIsSaving(false);
          return false
        }
      }
    } catch (err) {
      toast.error(`Failed to update user!`);
      console.error(`Failed to update user:`, err);
      setEditMode(false);
      setIsSaving(false);
      return false
    }
  };

  const upsertPermissions = async userId => {

    const allowedCustomersObj =
      allowedCustomers &&
      allowedCustomers.length &&
      allowedCustomers.length > 0 &&
      allowedCustomers.map(c => {
        return {
          customer_id: c.customer_id || c.id,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

    const allowedRolesObj =
      allowedRoleIds &&
      allowedRoleIds.length &&
      allowedRoleIds.length > 0 &&
      allowedRoleIds.map(r => {
        return {
          role_id: r,
          user_id: userId,
          updated_at: 'now()',
          updated_by: adminEmail,
          created_at: 'now()',
          created_by: adminEmail,
        };
      });

      try {
        if (allowedCustomersObj) {
            const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_CUSTOMERS, variables: { allowedCustomersObj: allowedCustomersObj } });
        }
        if (allowedRolesObj) {
            const res = await apolloClient.mutate({ mutation: UPSERT_ALLOWED_ROLES, variables: { allowedRolesObj: allowedRolesObj } });
        }
        return true
      } catch (err) {
        console.log('Error upserting new allowances:', err);
        toast.error('Failed to update allowances. User permissions could not be rebuilt');
        return false
      }
  };

  const handleCreateUser = async () => {
    setIsSaving(true);

    const formValidation = validateForm();

    if (!formValidation.valid) {
      if (formValidation.validation.customers === false) {
        toast.error(`Dealer-admins must have at least one allowed customer in addition to the default rooftop.`);
      } else if (formValidation.validation.regions === false) {
        toast.error(`Admins and dispatchers must have at least one allowed region.`);
      } else if (formValidation.validation.defaultRole === false) {
        toast.error(`Please select a role.`);
      } else if (formValidation.validation.email === false) {
        toast.error(`Please enter a valid email.`);
      } else if (formValidation.validation.phone === false) {
        toast.error(`Please enter a valid phone number.`);
      } else if (formValidation.validation.displayName === false) {
        toast.error(`Please enter a valid display name`)
      } else if 
        (formValidation.validation.displayName === false) {
          toast.error(`Please enter a valid display name`)
      } else {
        toast.error(`Form could not be validated. Please check for errors.`);
      }
    } else {
      try {
        const variables = {
          userObj: {
            active: true,
            email: email ? email.toLowerCase().trim() : null,
            phone: phone ? phone.trim() : null,
            avatar_url: avatarUrl ? avatarUrl : null,
            default_role: defaultRole ? defaultRole : null,
            display_name: displayName ? displayName : null,
            created_at: 'now()',
            updated_at: 'now()',
            customer_id: customerId ? customerId : null,
          },
        };

        const res = await apolloClient.mutate({mutation: CREATE_USER, variables: variables });
        if (res && res.data) {
          if (getPropValue(res, `data.insert_users.affected_rows`) > 0) {
            log && console.log(`Successfully created user record:`, res.data.insert_users);
            const newUsers = getPropValue(res, `data.insert_users.returning`);
            const newUserId = newUsers && newUsers.length && newUsers[0].id ? newUsers[0].id : null;
            log && console.log('NEWUSER', newUsers, newUserId);
            setUserId(newUserId)
            const permissionsRes = await upsertPermissions(newUserId);
            if (permissionsRes) {
              const updateUserRes = await handleSaveUserChanges(newUserId)
              if (updateUserRes) {
                toast.success(`Successfully created User ${newUserId}!`);
              }
            }
            await writeUserEventlog(adminEmail, newUserId, 'user.created', false);
            return { success: true, userId: newUserId }
          }
        }
      } catch (err) {
        if (err.toString().includes('duplicate key value violates unique constraint')) {
          toast.error(`User with email ${email} already exists!`);
          return { success: false }
        } else {
          toast.error(`Failed to create user!`);
          console.error(`Failed to create user:`, err);
          return {success: false}
        }
      } 
    }
    setIsSaving(false);
  };

  //Gear menu actions
  const terminateUser = async userId => {
    const res = await apolloClient.mutate({ mutation: TERMINATE_USER, variables: { userId } });
    const terminatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (terminatedUser) {
      return true;
    } else return false;
  };

  const reinstateUser = async userId => {
    const res = await apolloClient.mutate({ mutation: REINSTATE_USER, variables: { userId } });
    const reinstatedUser = getPropValue(res, `data.update_users.affected_rows`);
    if (reinstatedUser) {
      return true;
    } else return false;
  };

  // Set Context
  const context = {
    // Base State
    editMode,
    setEditMode,
    isSaving,
    setIsSaving,

    // Edit Vars
    active,
    phone,
    defaultRole,
    email,
    displayName,
    avatarUrl,
    updatedBy,
    allowedCustomers,
    payers,
    payees,
    customers,
    roles,
    allowedRegionIds,
    allowedCustomerIds,
    allowedRoleIds,
    validation,
    customerId,
    userId,
    adminCustomerId,

    // Set Edit Vars
    setActive,
    setDefaultRole,
    setEmail,
    setPhone,
    setDisplayName,
    setUpdatedBy,
    setAvatarUrl,
    setAllowedCustomers,
    setPayers,
    setPayees,
    setCustomers,
    setRoles,
    setCustomerId,
    setAllowedRegionIds,
    setAllowedCustomerIds,
    setAllowedRoleIds,
    setValidation,
    setAdminCustomerId,

    // Handler Functions
    handleSaveUserChanges,
    terminateUser,
    reinstateUser,
    handleSave,
    handleCreateUser,
    writeUserEventlog,
  };

  return <UserDetailsContext.Provider value={context}>{children}</UserDetailsContext.Provider>;
}

const useUserDetails = () => React.useContext(UserDetailsContext);

export { useUserDetails, UserDetailsProvider };
