import React from 'react';
import { withRouter } from 'react-router';
import { makeStyles, Grid, Typography, Tooltip, Chip } from '@material-ui/core';
import MoveStatusTracker from '../../reusable/MoveStatusTracker';
// import DashboardTileId from './DashboardTileId';
import PhoneNumber from '../../reusable/PhoneNumber';
import dayjs from 'dayjs';
import { useTools } from '../../hooks/useTools';

////////// COMPONENT //////////
function ConsumerTile(props) {
  const cls = useStyles();

  const { move } = props;

  const { getFormattedVehicleFromMove, getCancelHeaderFromMove, getCancelReasonFromMove } = useTools();

  const description = move.lane ? move.lane.description : 'No Description';

  const goToMoveDetails = () => {
    props.history.push(`/moves/${move.id}`);
  };

  return (
    <>
      <div className={cls.move} onClick={goToMoveDetails}>
        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap' className={cls.top}>
          <Grid item xs>
            <Tooltip title={`Vehicle - ${getFormattedVehicleFromMove(move)}`} placement='top-start'>
              <Typography className={cls.txtVehicle + ' ' + cls.ellipse}>
                {getFormattedVehicleFromMove(move)}
              </Typography>
            </Tooltip>

            <Tooltip title={`Customer - ${move.consumer_name} ${move.consumer_phone}`} placement='top-start'>
              <Typography className={cls.txtConsumer + ' ' + cls.ellipse}>
                {move.consumer_name} <PhoneNumber phone={move.consumer_phone} />
              </Typography>
            </Tooltip>

            <Tooltip title={`Lane - ${description}`} placement='top-start'>
              <Typography className={cls.txtLane}>{description}</Typography>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={`Ready By Time - ${dayjs(move.ready_by).format('M/D h:mm A z')}`} placement='top-end'>
              <Typography className={cls.txtTime}>{dayjs(move.ready_by).format('M/D h:mm A z')}</Typography>
            </Tooltip>

            <Chip style={{ float: `right` }} label='Concierge' size='small' color='secondary' />
          </Grid>
        </Grid>

        <MoveStatusTracker move={move} size='small' />

        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap' className={cls.bottom}>
          <Grid item xs>
            <Tooltip title={`HopDrive Move ID - #${move.id || `???`}`} placement='bottom-start'>
              <Typography className={cls.txtVal} style={{ textAlign: 'left' }}>
                #{move.id || `-`}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item xs>
            <Tooltip title={`Vehicle VIN - ${move.vehicle_vin || `???`}`} placement='bottom'>
              <Typography className={cls.txtVal} style={{ textAlign: 'center' }}>
                {move.vehicle_vin || `-`}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item xs>
            <Tooltip
              title={
                move.reference_num
                  ? `Reference ID - ${move.reference_num || `???`}`
                  : `Vehicle Stock - ${move.vehicle_stock || `???`}`
              }
              placement='bottom-end'
            >
              <Typography className={cls.txtVal} style={{ textAlign: 'right' }}>
                {move.reference_num || move.vehicle_stock || `-`}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems='flex-start' wrap='nowrap' className={cls.bottom}>
          <Grid item xs>
            <Tooltip title={`User who created the move`} placement='top-start'>
              <Typography className={cls.txtLane}>
                {move.createdBy
                  ? move.createdBy
                  : move.eventlogs && move.eventlogs.length > 0
                  ? move.eventlogs[0].user
                  : ''}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>

        {move.cancel_status ? (
          <div className={cls.cancel}>
            <Typography className={cls.cancelTxt}>{getCancelHeaderFromMove(move)}</Typography>
            <Typography className={cls.cancelReasonTxt}>{getCancelReasonFromMove(move)}</Typography>
          </div>
        ) : null}

        {/* <DashboardTileId move={move} /> */}
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  move: {
    position: 'relative',
    padding: theme.spacing(1.5),
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    '&:hover': {
      border: `2px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary.lighter,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },
  top: {
    minWidth: '100%',
    marginBottom: theme.spacing(0.5),
  },
  bottom: {
    minWidth: '100%',
    marginTop: theme.spacing(0.5),
  },
  txtVehicle: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  txtConsumer: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  txtLane: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  txtTime: {
    textAlign: 'right',
    lineHeight: 1.333,
    fontSize: 12,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  txtVal: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  cancel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    backgroundColor: '#000000b4',
  },
  cancelTxt: {
    position: 'absolute',
    top: '32%',
    left: 0,
    right: 0,
    color: '#fff',
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 21,
    },
  },
  cancelReasonTxt: {
    position: 'absolute',
    top: '56%',
    left: 0,
    right: 0,
    color: '#fff',
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  ellipse: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

////////// EXPORT //////////
export default withRouter(ConsumerTile);
