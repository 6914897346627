import React from 'react';
import moment from 'moment';

import { makeStyles, Grid, Typography } from '@material-ui/core';

////////// TOOLS //////////
const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
export default function ARInvoiceFooter(props) {
  const cls = useStyles();

  const { selectedInvoice, amount } = props;
  const payments = selectedInvoice.arpayments;

  const paidPayments = payments && payments.length > 0 ? payments.filter(p => p.status === `successful`).map(p => p.amount) : [];
  const paidTotal = paidPayments && paidPayments.length > 0 ? paidPayments.reduce((total, current) => total + current) : 0;

  return (<>
    {selectedInvoice && selectedInvoice !== null &&
      <Grid container spacing={2}>
        {payments && payments.length > 0 ?
          <Grid item md={8} xs={12}>
            <div className={cls.paper}>

              <Typography className={cls.headTxt}>Payment History</Typography>

              <div className={cls.break} />

              <Grid container spacing={1} wrap="nowrap">
                <Grid item xs={1}><Typography align="left" className={cls.payKey}>ID</Typography></Grid>
                <Grid item xs={2}><Typography align="left" className={cls.payKey}>Date</Typography></Grid>
                <Grid item xs={3}><Typography align="left" className={cls.payKey}>Transaction&nbsp;ID</Typography></Grid>
                <Grid item xs={2}><Typography align="left" className={cls.payKey}>Status</Typography></Grid>
                <Grid item xs={2}><Typography align="right" className={cls.payKey}>Amount</Typography></Grid>
              </Grid>

              <div className={cls.lineBreak} />

              {payments.map((payment, i) => <React.Fragment key={"paymentRow" + payment.id}>
                <Grid container spacing={1} wrap="nowrap">
                  <Grid item xs={1}><Typography align="left" className={cls.payVal}>{payment.id}</Typography></Grid>
                  <Grid item xs={2}><Typography align="left" className={cls.payVal}>{moment(payment.createdat).format(`MM/DD/YYYY`)}</Typography></Grid>
                  <Grid item xs={3}><Typography align="left" className={cls.payVal}>{payment.gateway_transaction_id || `-`}</Typography></Grid>
                  <Grid item xs={2}><Typography align="left" className={cls.payVal}>{capFirst(payment.status)}</Typography></Grid>
                  <Grid item xs={2}><Typography align="right" className={cls.payVal}>${payment.amount.toFixed(2)}</Typography></Grid>
                </Grid>
                {i < payments.length - 1 ? <div className={cls.lineBreak} /> : null}
              </React.Fragment>)}

              <div className={cls.bigBreak} />

              <Grid container spacing={0}>
                <Grid item xs><Typography className={cls.totalKey}>Actual Paid Total (*Includes additional fees):</Typography></Grid>
                <Grid item><Typography className={cls.totalVal}>${paidTotal.toFixed(2)}</Typography></Grid>
              </Grid>

            </div>
          </Grid> :
          <Grid item md={8} sm={6} xs={false}></Grid>
        }
        <Grid item md={4} sm={6} xs={12}>
          <div className={cls.paper}>

            <Typography className={cls.headTxt}>Calculated Totals</Typography>

            <div className={cls.smallBreak} />

            {amount.discounted || amount.disputed ? <>
              <Typography display="inline" className={cls.key}>Subtotal&nbsp;</Typography>
              <Typography display="inline" className={cls.val}>${amount.subtotal.toFixed(2)}</Typography>
              <br />
              {amount.discounted ? <>
                <Typography display="inline" className={cls.key}>Discounted&nbsp;</Typography>
                <Typography display="inline" className={cls.val}>(${amount.discounted.toFixed(2)})</Typography>
                <br />
              </> : null}
              {amount.disputed ? <>
                <Typography display="inline" className={cls.key}>Disputed&nbsp;</Typography>
                <Typography display="inline" className={cls.val}>(${amount.disputed.toFixed(2)})</Typography>
                <br />
              </> : null}
              <div className={cls.lineBreak} />
            </> : null}

            <Typography display="inline" className={cls.keyDemi}>Total&nbsp;</Typography>
            <Typography display="inline" className={cls.valDemi}>${amount.total.toFixed(2)}</Typography>
            
            <div className={cls.lineBreak} />
            
            <Typography display="inline" className={cls.keyBold}>Amount&nbsp;Paid&nbsp;</Typography>
            <Typography display="inline" className={cls.valBold}>${amount.paid.toFixed(2)}</Typography>
            <br />
            <Typography display="inline" className={cls.keyBold}>Amount&nbsp;Due&nbsp;</Typography>
            <Typography display="inline" className={cls.valBold}>${amount.dueSans.toFixed(2)}</Typography>

          </div>
        </Grid>
      </Grid>
    }
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  totalKey: {
    verticalAlign: 'top',
    marginTop: '1px',
    marginRight: theme.spacing(2),
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '10px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
    },
  },
  totalVal: {
    verticalAlign: 'top',
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  payKey: {
    fontSize: '12px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  payVal: {
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  key: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  val: {
    float: 'right',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyDemi: {
    color: theme.palette.text.tertiary,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valDemi: {
    float: 'right',
    color: theme.palette.text.tertiary,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyBold: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valBold: {
    float: 'right',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '8px 0',
    background: theme.palette.border,
  },
}));
