//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
  Chip,
  CardMedia,
  Button,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import { useUserDetails } from './UserDetailsProvider';
import ReusableMultiselect from '../../reusable/ReusableMultiselect/ReusableMultiselect';
import AllowedCustomersTable from './AllowedCustomers/AllowedCustomersTable';
import AvatarUpload from './AvatarUpload';
import { useTools } from '../../hooks/useTools';
import { getUserId } from '../../utils/authHelper';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function UserDetailsInfo({ user, customerSelections, setCustomerSelections }) {
  const cls = useStyles();
  const ctx = useUserDetails();
  const { getCleansedPhoneNumber } = useTools();

  const [defaultCustomerDisplay, setDefaultCustomerDisplay] = React.useState('');
  const [defaultCustomer, setDefaultCustomer] = React.useState(null);
  const [currentUserId, setCurrentUserId] = React.useState(null);


  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleCustomerIdChange = value => {
    ctx.setCustomerId(value.props.value);
  };

  React.useEffect(() => {
    const fetchCurrentUserId = async () => {
      let id = await getUserId();
      if (id) setCurrentUserId(parseInt(id));
    };
    fetchCurrentUserId();
  }, [])


  const handleSelectedCustomers = selections => {
    if (ctx && ctx.setAllowedCustomers) ctx.setAllowedCustomers(selections);
  };

  const handleRemoveCustomer = index => {
    if (ctx && ctx.setAllowedCustomers) ctx.setAllowedCustomers(prevCustomers => {
      return prevCustomers.filter((_, i) => i !== index);
    });

    if (ctx && ctx.setAllowedCustomerIds) ctx.setAllowedCustomerIds(prevIds => {
      const updatedIds = [...prevIds];
      updatedIds.splice(index, 1);
      return updatedIds;
    });
  };

  React.useEffect(() => {
    if (customerSelections && customerSelections.length > 0 && ctx.defaultRole === 'dealer-admin') {
      let customers = [];
      const customer = customerSelections.find(c => c.id === (ctx.customerId || user.customer_id));
      if (!customer) return;

      setDefaultCustomer(customer);
      setDefaultCustomerDisplay(`${customer.name || ''} (#${customer.id || ''})`);

      // Only update allowedCustomers if we have a valid change to make
      if (!ctx.allowedCustomers || !ctx.allowedCustomers.length) {
        customers = [customer];
        ctx.setAllowedCustomers(customers);
      } else if (
        !ctx.allowedCustomers.find(c => c.id === customer.id) &&
        !ctx.allowedCustomers.find(c => c.id === customer.customer_id)
      ) {
        customers = [...ctx.allowedCustomers, customer];
        ctx.setAllowedCustomers(customers);
      }
    }
  }, [customerSelections, ctx.defaultRole, ctx.customerId]);

  //Keep array of selected customer ids from multiselect in step with allowed customers
  React.useEffect(() => {
    if (ctx.allowedCustomers && ctx.allowedCustomers.length > 0) {
      const customerIds = ctx.allowedCustomers.map(c => c.id || c.customer_id);
      const uniqueCustomerIds = customerIds.filter((num, index, self) => {
        return self.indexOf(num) === index;
      });
      ctx.setAllowedCustomerIds(uniqueCustomerIds);
    }
  }, [ctx.allowedCustomers]);

  return (
    <Container maxWidth='lg'>
      {/* VITALS */}
      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Display Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                required
                fullWidth
                multiline
                label='Display Name'
                placeholder='Enter display name...'
                variant='outlined'
                size='small'
                value={ctx.displayName || ''}
                onChange={handleInputChange(ctx.setDisplayName)}
                error={ctx && ctx.validation && ctx.validation.displayName === false}
                helperText={!ctx.displayName ? `A display name is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        person
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography>{ctx.displayName}</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Email <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode && !user.active ? (
              <TextField
                disabled={ctx.isSaving}
                required
                fullWidth
                multiline
                label='Email'
                placeholder='Enter email address...'
                variant='outlined'
                size='small'
                value={ctx.email || ''}
                onChange={handleInputChange(ctx.setEmail)}
                error={ctx && ctx.validation && ctx.validation.email && ctx.validation.email === false}
                helperText={ctx && ctx.validation && ctx.validation.email && ctx.validation.email === false ? `A valid email address is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        mail
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography className={cls.link}>
                <a href={`mailto:${ctx.email || null}`}>{ctx.email || null}</a>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>Phone (Optional)</Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                fullWidth
                multiline
                label='Phone'
                placeholder='Enter phone number...'
                variant='outlined'
                size='small'
                value={ctx.phone || ''}
                onChange={handleInputChange(ctx.setPhone)}
                error={ctx && ctx.validation && ctx.validation.phone && ctx.validation.phone === false}
                helperText={ctx && ctx.validation && ctx.validation.phone && ctx.validation.phone === false ? `Phone number is invalid!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        phone
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography className={cls.link}>
                <a href={`tel:${ctx.phone || null}`}>{getCleansedPhoneNumber(ctx.phone || null)}</a>
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      {/*<Grid container wrap='nowrap'>*/}
      {/*  <Grid item xs={titleGridWidth}>*/}
      {/*    <div className={cls.sectionL}>*/}
      {/*      <Typography className={cls.sectionTitleTxt}>Avatar (Optional)</Typography>*/}
      {/*    </div>*/}
      {/*  </Grid>*/}

      {/*  <Grid item>*/}
      {/*    <div className={cls.sectionLine} />*/}
      {/*  </Grid>*/}

      {/*  <Grid item xs>*/}
      {/*    <div className={cls.sectionR}>*/}
      {/*      {ctx.editMode ? (*/}
      {/*        <AvatarUpload avatar={ctx.avatarUrl} setAvatar={ctx.setAvatarUrl} userId={ctx.userId} />*/}
      {/*      ) : (*/}
      {/*        <>*/}
      {/*          {ctx.avatarUrl ? (*/}
      {/*            <CardMedia*/}
      {/*              component='img'*/}
      {/*              alt='User profile picture'*/}
      {/*              style={{ width: '25%' }}*/}
      {/*              image={ctx.avatarUrl}*/}
      {/*              title=''*/}
      {/*            />*/}
      {/*          ) : null}*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

      {/* DEFAULT CUSTOMER */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Default Rooftop <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <Typography className={cls.sectionSubtitleTxt}>
              The main rooftop with which the user is associated. This field is required for all users.
              Users with the super admin role receive permissions for all
              rooftops within the organization.
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode ? (
              <TextField
                disabled={ctx.isSaving}
                select
                fullWidth
                label='Default Rooftop'
                placeholder='Select the default rooftop...'
                variant='outlined'
                size='small'
                defaultValue={customerSelections && customerSelections.length && customerSelections.length > 0 && customerSelections.find(c => c.id === user.customer_id).id}
                value={customerSelections && customerSelections.length && customerSelections.length > 0 && customerSelections.find(c => c.id === ctx.customerId).id}
                onChange={(event, value) => handleCustomerIdChange(value)}
                error={ctx && ctx.validation && ctx.validation.customerId && ctx.validation.customerId === false}
                helperText={ctx && ctx.validation && ctx.validation.customerId && ctx.validation.customerId === false ? `Default customer is required!` : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        roofing
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                {customerSelections &&
                  customerSelections.length &&
                  customerSelections.map((c, i) => (
                    <MenuItem key={`organization-${c.id}`} value={c.id}>
                      {c.name } (#{c.id})
                    </MenuItem>
                  ))}
              </TextField>
            ) : (
                <Typography className={cls.link}>
                  {defaultCustomerDisplay}
                </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      {/* ROLE */}

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              User Role <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
            <Spacer />
            <>
              <Typography className={cls.sectionSubtitleTxt}>
                Select a default role for the user, which will determine their level of access to rooftop(s).
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
              <b>Super Admin</b> - This user will have full, automatic access to all rooftops within an
              organization. They can also add new users and super admins.
            </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                <b>Admin</b> - This user will have access to the subset of rooftops that you will be
                prompted to specify.
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                <b>User</b> - This role will have access to a single rooftop that you specify.
              </Typography>
            </>
            {/* )} */}
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            {ctx.editMode && currentUserId !== user.id? (
              <TextField
                disabled={
                  ctx.isSaving
                }
                required
                select
                fullWidth
                label='Role'
                placeholder='Select a role...'
                variant='outlined'
                size='small'
                value={ctx.defaultRole}
                onChange={handleInputChange(ctx.setDefaultRole)}
                error={ctx && ctx.validation && ctx.validation.defaultRole && ctx.validation.defaultRole === false}
                helperText={ctx && ctx.validation && ctx.validation.defaultRole && ctx.validation.defaultRole === false ? 'Select a default role!' : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        category
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value='dealer-super-admin'>Super Admin</MenuItem>
                <MenuItem value='dealer-admin'>Admin</MenuItem>
                <MenuItem value='dealer'>User</MenuItem>
              </TextField>
            ) : (
              <Typography>
                {ctx.defaultRole && ctx.defaultRole === 'dealer-super-admin' ? 'Super Admin' : ctx.defaultRole && ctx.defaultRole === 'dealer' ? 'User' : ctx.defaultRole && ctx.defaultRole === 'dealer-admin' ? 'Admin' : null}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>

      {/* ALLOWED CUSTOMERS */}

      {ctx && ctx.defaultRole && ctx.defaultRole === 'dealer-admin' && (
        <Grid container wrap='nowrap'>
          <Grid item xs={titleGridWidth}>
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>
                Allowed Rooftops <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                Admin users must be given access to at least one rooftop in addition to their default.
              </Typography>
            </div>
          </Grid>

          <Grid item>
            <div className={cls.sectionLine} />
          </Grid>

          <Grid item xs>
            <div className={cls.sectionR}>
              {ctx.editMode ? (
                <>
                  <ReusableMultiselect
                    dataArray={customerSelections}
                    selectedArray={ctx && ctx.allowedCustomerIds && ctx.allowedCustomerIds.length ? ctx.allowedCustomerIds : []}
                    optionName={'name'}
                    label={'Allowed Rooftop'}
                    handleOutput={handleSelectedCustomers}
                  />

                  <Spacer />
                  {ctx && ctx.allowedCustomers && ctx.allowedCustomers.length && ctx.allowedCustomers.map((customer, index) => (
                    <Chip
                      key={index}
                      style={{ margin: 5 }}
                      label={customer.name || customer.customer.name || ''}
                      onDelete={() => handleRemoveCustomer(index)}
                    />
                  ))}
                  {ctx && ctx.validation && ctx.validation.allowedCustomers && ctx.validation.allowedCustomers === false && (
                    <Typography variant='body2' color='error'>
                      Admins must have access to at least one rooftop in addition to their default.
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <AllowedCustomersTable 
                    allowedCustomers={ctx && ctx.allowedCustomers ? ctx.allowedCustomers : []} 
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  titleChipIcon: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));
